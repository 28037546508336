import Container from "react-bootstrap/Container";
import banner from "../../assets/desktopapps.jpg";
import BannerControl from "../banner.control";
import { Helmet } from "react-helmet";

const MobileAppsPage = () => {
  return (
    <div>
      <Helmet>
        <title>Services - Desktop apps - Innoware</title>
      </Helmet>
      <BannerControl
        banner={banner}
        title="Desktop apps"
        subtitle="When speed is of essence"
      />
      <section className="py-5" style={{ backgroundColor: "#eeeeee" }}>
        <Container>
          <h2>Desktop apps</h2>
          <p>
            Have the need for an app the runs natively on windows or macOS then
            we can help. Desktop apps have better performance than web apps and
            does not require an internet connection to run.
          </p>
        </Container>
      </section>
    </div>
  );
};

export default MobileAppsPage;
