import Container from "react-bootstrap/Container";
import banner from "../assets/howitworks.jpg";
import workflow from "../assets/workflow.svg";
import BannerControl from "./banner.control";
import { Helmet } from "react-helmet";

const HowItWorksPage = () => {
  return (
    <div>
      <Helmet>
        <title>How it works - Innoware</title>
      </Helmet>
      <BannerControl
        banner={banner}
        title="How it works"
        subtitle="The way we work"
      />

      <section className="py-5" style={{ backgroundColor: "#eeeeee" }}>
        <Container>
          <h2>How it works</h2>
          <p>
            Most companies already know that with the right software solution
            many processes could be improved but often do not know who to
            contact for help. We are that company.
          </p>
          <p>
            We help our clients by designing the perfect customized software
            solution. Once we agree on the solution we develop and implement it.
          </p>
          <p>
            If you feel like you could use a customized software solution please
            contact us and together we design the perfect solution at the right
            price.
          </p>
          <p>
            <h5>Process</h5>
          </p>
          <p>The following represents a normal workflow:</p>
          <div style={{ textAlign: "center" }}>
            <img
              src={workflow}
              style={{ objectFit: "contain", maxHeight: 500, maxWidth: "100%" }}
            />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default HowItWorksPage;
