import Container from "react-bootstrap/Container";
import banner from "../../assets/3dprogramming.jpg";
import BannerControl from "../banner.control";
import { Helmet } from "react-helmet";

const ThreeDProgrammingPage = () => {
  return (
    <div>
      <Helmet>
        <title>Services - 3d programming - Innoware</title>
      </Helmet>
      <BannerControl
        banner={banner}
        title="3d programming"
        subtitle="Taking objects outside your unit
        "
      />
      <section className="py-5" style={{ backgroundColor: "#eeeeee" }}>
        <Container>
          <h2>3d programming</h2>
          <p>
            We are experienced in implementing 3D graphics into applications and
            web pages.
          </p>
        </Container>
      </section>
    </div>
  );
};

export default ThreeDProgrammingPage;
