import Container from "react-bootstrap/Container";
import banner from "../../assets/plugins.jpg";
import BannerControl from "../banner.control";
import { Helmet } from "react-helmet";

const PluginsPage = () => {
  return (
    <div>
      <Helmet>
        <title>Services - Plugins and add-ons - Innoware</title>
      </Helmet>
      <BannerControl
        banner={banner}
        title="Plugins and add-ons"
        subtitle="Extending your app"
      />
      <section className="py-5" style={{ backgroundColor: "#eeeeee" }}>
        <Container>
          <h2>Plugins and add-ons</h2>
          <p>
            Need to extend the usability of a program? We have experience in
            writing scripts, plug-ins and add-ons. By extending the
            functionality of a program, many daily operations can be automated,
            simplified and validated.
          </p>
        </Container>
      </section>
    </div>
  );
};

export default PluginsPage;
