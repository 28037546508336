const BannerControl = ({ banner, title, subtitle }) => {
  return (
    <section style={{ position: "relative", height: 430, zIndex: 10 }}>
      <img
        src={banner}
        style={{ width: "100%", height: 430, objectFit: "cover" }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "black",
          opacity: 0.5,
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "white",
          fontSize: 32,
        }}
      >
        <div style={{ marginBottom: "0.6em", textAlign: "center" }}>
          {title}
        </div>
        <div
          style={{
            fontSize: "15px",
            textAlign: "center",
          }}
        >
          {subtitle}
        </div>
      </div>
    </section>
  );
};

export default BannerControl;
