import Container from "react-bootstrap/Container";
import banner from "../assets/services.jpg";
import BannerControl from "./banner.control";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const ServicesPage = () => {
  return (
    <div>
      <Helmet>
        <title>Services - Innoware</title>
      </Helmet>
      <BannerControl banner={banner} title="Services" subtitle="What we do" />
      <section className="py-5" style={{ backgroundColor: "#eeeeee" }}>
        <Container>
          <h2>Services</h2>
          <p>
            At Innoware we are developing software solutions that provide your
            business the advantage it needs in order to grow, stay organized and
            stay ahead of competition.
          </p>
          <p>
            It is our experience that the right software solution can make a
            huge impact in daily routines and quickly pay itself back and
            improve work quality by reducing margin of error and speed of
            operations.
          </p>
          <p>
            Being situated in Monterrey, Mexico, Innoware has the competitive
            advantage of access to quality software developers. You will find
            our prices attractive and rest assure that we are committed to
            finding you the right software solution.
          </p>
          <p>
            We offer the following services inside the world of programming:
          </p>
          <ul>
            <li>
              <Link to="homepages" className="a-link">
                Homepages
              </Link>
            </li>
            <li>
              <Link to="process_automation" className="a-link">
                Process automation
              </Link>
            </li>
            <li>
              <Link to="mobile_apps" className="a-link">
                Mobile apps
              </Link>
            </li>
            <li>
              <Link to="desktop_apps" className="a-link">
                Desktop apps
              </Link>
            </li>
            <li>
              <Link to="3d_programming" className="a-link">
                3d programming
              </Link>
            </li>
            <li>
              <Link to="plugins_and_addons" className="a-link">
                Plugins and add-ons
              </Link>
            </li>
          </ul>
        </Container>
      </section>
    </div>
  );
};

export default ServicesPage;
