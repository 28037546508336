const Footer = () => {
  return (
    <footer
      style={{ height: "120px", backgroundColor: "black" }}
      className="text-center d-flex justify-content-center align-items-center"
    >
      <span style={{ color: "white", fontSize: "10pt" }}>
        Contact:{" "}
        <a style={{ color: "white" }} href="mailto:contact@innoware.mx">
          contact@innoware.mx
        </a>
      </span>
    </footer>
  );
};
export default Footer;
