import Container from "react-bootstrap/Container";
import banner from "../../assets/mobileapps.jpg";
import BannerControl from "../banner.control";
import { Helmet } from "react-helmet";

const MobileAppsPage = () => {
  return (
    <div>
      <Helmet>
        <title>Services - Mobile apps - Innoware</title>
      </Helmet>
      <BannerControl
        banner={banner}
        title="Mobile apps"
        subtitle="Access at your fingertips"
      />
      <section className="py-5" style={{ backgroundColor: "#eeeeee" }}>
        <Container>
          <h2>Mobile apps</h2>
          <p>
            We can help your idea and vision for a mobile app become a reality.
            Together we can decide on the right approach for building your
            application which will make you stand out from the competition.
          </p>
        </Container>
      </section>
    </div>
  );
};

export default MobileAppsPage;
