import Container from "react-bootstrap/Container";
import banner from "../../assets/processautomation.jpg";
import BannerControl from "../banner.control";
import { Helmet } from "react-helmet";

const ProcessAutomationPage = () => {
  return (
    <div>
      <Helmet>
        <title>Services - Process automation - Innoware</title>
      </Helmet>
      <BannerControl
        banner={banner}
        title="Process automation"
        subtitle="The process of freeing time to sell more"
      />
      <section className="py-5" style={{ backgroundColor: "#eeeeee" }}>
        <Container>
          <h2>Process automation</h2>
          <p>
            Many daily tasks performed by people can be performed by software
            instead. We can help develop the right solution for you and automate
            processes, like understanding what is shown on a screen, complete
            keystrokes, identify and extract data and perform a wide range of
            defined actions.
          </p>
          <p>
            Software process automation solves trivial tasks faster and more
            consistently than people. It helps increase employee satisfaction,
            engagement, and productivity by removing boring and repetitive tasks
            from their workdays. Suddenly your employees are free to focus on
            the things they do best and enjoy more.
          </p>
        </Container>
      </section>
    </div>
  );
};

export default ProcessAutomationPage;
