import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import banner from "../assets/softwareDevelopment.jpg";
import BannerControl from "./banner.control";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faCode,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    <div>
      <BannerControl
        banner={banner}
        title="Software development"
        subtitle="Optimizing Business through Innovating Software"
      />
      <Helmet>
        <title>Home - Innoware</title>
      </Helmet>
      <section className="py-5" style={{ backgroundColor: "#eeeeee" }}>
        <Container>
          <div className="row g-5">
            <div className="col-xs-12 col-md-6 col-lg-4 text-center ">
              <div style={{ fontSize: 40 }}>
                <FontAwesomeIcon icon={faLightbulb} />
              </div>
              <div style={{ fontSize: "1.666rem" }}>Design</div>
              <div className="d-flex justify-content-center">
                <div className="seperator"></div>
              </div>
              <div>
                <p>
                  Together with the client, we design and improve the solution
                  during the complete software development process. This reduces
                  misunderstandings, time, errors, and has proven to create
                  perfect results while keeping the price down. Whether you want
                  to design a new software solution or extend an existing one,
                  we are the go-to company.
                  <br />
                  <br />
                  As your company grows, so does the requirement of your
                  software. We follow good design principles which lowers future
                  development costs.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4 text-center ">
              <div style={{ fontSize: 40 }}>
                <FontAwesomeIcon icon={faCode} />
              </div>
              <div style={{ fontSize: "1.666rem" }}>Development</div>
              <div className="d-flex justify-content-center">
                <div className="seperator"></div>
              </div>
              <div>
                <p>
                  We make your specifications come to life. With more than 40
                  years of software development experience, we know how to
                  convert your specifications into a perfect functioning and
                  modern software solution.
                  <br />
                  We help install, setup, and configure the solution after
                  everything is developed. It can run on our infrastructure or
                  on your infrastructure depending on your needs.
                  <br />
                  <br />
                  We consider infrastructure according to future growth
                  requirements to make sure that the solution can run for years
                  to come.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4 text-center ">
              <div style={{ fontSize: 40 }}>
                <FontAwesomeIcon icon={faWrench} />
              </div>
              <div style={{ fontSize: "1.666rem" }}>Maintenance</div>
              <div className="d-flex justify-content-center">
                <div className="seperator"></div>
              </div>
              <div>
                <p>
                  As time pass projects require new functionality. We continue
                  our relationship and help your app stay up to date.
                  <br />
                  <br />
                  Due to good design principles at the beginning, software
                  changes and/or additions continue to be low cost.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 text-center">
            <a
              href="mailto:contact@innoware.mx"
              className="btn btn-primary button-link"
              style={{
                backgroundColor: "#0274be",
                borderRadius: 0,
                paddingLeft: 40,
                paddingRight: 40,
              }}
            >
              CONTACT US
            </a>
          </div>
        </Container>
      </section>
      <section className="py-0">
        <Carousel data-bs-theme="dark">
          <Carousel.Item className="innoware-carousel-item">
            <Carousel.Caption style={{ color: "black" }}>
              <p>
                With lots of hard work and timely communication they made sure
                they delivered the best to us. Highly recommended!
              </p>
              <h3>Lars Bjørn</h3>
              <p style={{ fontStyle: "italic" }}>Suncil</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="innoware-carousel-item">
            <Carousel.Caption style={{ color: "black" }}>
              <p>
                I have been working with these guys since 15 years now! From
                homepages to automation, desktop apps, and mobile apps, they do
                it all. Thanks Innoware!
              </p>
              <h3>Anni Sund</h3>
              <p style={{ fontStyle: "italic" }}>Capp2</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="innoware-carousel-item">
            <Carousel.Caption style={{ color: "black" }}>
              <p>
                We had a huge problem with money transparency in our colony.
                Innoware helped us sort out all our accounts, creating an easy
                to use spreadsheet that allows us to focus on registering and
                create transparency to our users. Now users can focus on real
                complaints instead of complaining about the administration.
              </p>
              <h3>Presidente</h3>
              <p style={{ fontStyle: "italic" }}>Fraccionamiento Himalaya</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
    </div>
  );
};

export default HomePage;
