import "./App.css";
import { Routes, Route } from "react-router-dom";

import HeaderControl from "./header.control";
import FooterControl from "./footer.control";

import HomePage from "./home.page";

import ServicesPage from "./services.page";
import ServicesHomepagesPage from "./services/homepages.page";
import ServicesProcessAutomationPage from "./services/processautomation.page";
import ServicesMobileAppsPage from "./services/mobileapps.page";
import ServicesDesktopAppsPage from "./services/desktopapps.page";
import Services3dProgrammingPage from "./services/3dprogramming.page";
import ServicesPluginsPage from "./services/plugins.page";

import HowItWorksPage from "./howitworks.page";
import ContactPage from "./contact.page";

function App() {
  return (
    <div>
      <HeaderControl />

      <Routes>
        <Route path="/">
          <Route index element={<HomePage />} />
          <Route path="services">
            <Route index element={<ServicesPage />} />
            <Route path="homepages" element={<ServicesHomepagesPage />} />
            <Route
              path="process_automation"
              element={<ServicesProcessAutomationPage />}
            />
            <Route path="mobile_apps" element={<ServicesMobileAppsPage />} />
            <Route path="desktop_apps" element={<ServicesDesktopAppsPage />} />
            <Route
              path="3d_programming"
              element={<Services3dProgrammingPage />}
            />
            <Route
              path="plugins_and_addons"
              element={<ServicesPluginsPage />}
            />
          </Route>
          <Route path="howitworks" element={<HowItWorksPage />} />
          <Route path="contact" element={<ContactPage />} />
        </Route>
      </Routes>

      <FooterControl />
    </div>
  );
}

export default App;
