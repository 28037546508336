import Container from "react-bootstrap/Container";
import banner from "../../assets/homepages.jpg";
import BannerControl from "../banner.control";
import { Helmet } from "react-helmet";

const HomePagesPage = () => {
  return (
    <div>
      <Helmet>
        <title>Services - Homepages - Innoware</title>
      </Helmet>
      <BannerControl
        banner={banner}
        title="Homepages"
        subtitle="Exposing your company to the world"
      />
      <section className="py-5" style={{ backgroundColor: "#eeeeee" }}>
        <Container>
          <h2>Homepages</h2>
          <p>
            Companies use homepages to expose their services to clients in an
            automatic way. At Innoware we have seen first hand the
            transformation from a non exposed company to an exposed company and
            how this can canalize into profit.
          </p>
          <p>
            We create homepages in WordPress, it is a de-facto standard, more
            than 50% of the world uses it. Pages created in WordPress has the
            following benefits:
          </p>
          <ul>
            <li>
              Standardized. Your homepage is future proof and further
              development is cheap.
            </li>
            <li>Looks great on desktop and mobile</li>
          </ul>
          <p>
            We also provide custom made homepages. This can be the case when you
            want to extend your existing homepage or because not all can be done
            in WordPress
          </p>
        </Container>
      </section>
    </div>
  );
};

export default HomePagesPage;
