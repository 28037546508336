import Container from "react-bootstrap/Container";
import banner from "../assets/contact.jpg";
import BannerControl from "./banner.control";
import GoogleMapReact from "google-map-react";
import settings from "../utils/settings";
import { Helmet } from "react-helmet";

const center = settings.company.gps;
const zoom = 10;

const ContactPage = () => {
  const handleApiLoaded = (map, maps) => {
    //const image = "/assets/marker11.png";
    new maps.Marker({
      position: center,
      map,
      //icon: image,
    });
  };

  return (
    <div>
      <Helmet>
        <title>Contact - Innoware</title>
      </Helmet>
      <BannerControl
        banner={banner}
        title="Contact Us"
        subtitle="We look forward to hearing from you"
      />

      <section className="py-5" style={{ backgroundColor: "#eeeeee" }}>
        <Container>
          <div className="row">
            <div className="col-12">
              <h2>Contact us</h2>
              <p>
                Internally we speak binary, but to our clients we speak english,
                danish and spanish.
              </p>
              <p>Feel free to contact us:</p>
              <p>
                Email:{" "}
                <a href="mailto:contact@innoware.mx">contact@innoware.mx</a>
              </p>
            </div>
            <div className="col-12 mt-3" style={{ height: 400 }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: settings.google.maps.key,
                }}
                defaultCenter={center}
                defaultZoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                  handleApiLoaded(map, maps)
                }
              ></GoogleMapReact>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default ContactPage;
