import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { useState } from "react";

// If menu should come from left, look into offcanvas navbar

const Header = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <header>
      <div
        style={{
          backgroundColor: "#1e73be",
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 16,
        }}
        className="text-end"
      >
        <Container>
          <span style={{ color: "white", fontWeight: 700, fontSize: "10pt" }}>
            Contact:{" "}
            <a style={{ color: "white" }} href="mailto:contact@innoware.mx">
              contact@innoware.mx
            </a>
          </span>
        </Container>
      </div>
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{ height: 70 }}
        onToggle={() => setIsExpanded(!isExpanded)}
        expanded={isExpanded}
      >
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} className="d-inline-block" alt="Innoware" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            style={{ backgroundColor: "white", zIndex: 100 }}
          >
            <Nav
              className="justify-content-end"
              style={{ width: "100%", backgroundColor: "#f8f9fa" }}
            >
              <Nav.Link as={Link} to="/" onClick={() => setIsExpanded(false)}>
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/services"
                onClick={() => setIsExpanded(false)}
              >
                Services
              </Nav.Link>
              <NavDropdown title="Products">
                <NavDropdown.Item href="https://fotisima.mx/">
                  Fotisima
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                as={Link}
                to="/howitworks"
                onClick={() => setIsExpanded(false)}
              >
                How it works
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact"
                onClick={() => setIsExpanded(false)}
              >
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
